<template>
  <div>
    <PublishDesignerCard :designer="designer" />
    <a-row class="mt-12" :gutter="24">
      <a-col :xs="24" :md="24" :lg="12">
        <p class="text-gray-400">
          Please note that this image is only for your dashboard account.
        </p>
        <a-form>
          <a-form-item>
            <a-input type="file" @change="handleAvatarFile" />
          </a-form-item>
          <a-form-item>
            <AppButton
              buttonText="Submit &amp; Save"
              @buttonAction="handleAvatarUpload"
              :loading="buttonLoading"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :xs="24" :md="24" :lg="12">
        <p class="text-gray-400">
          Please note that this image will be displayed to aura's users when u
          publish your account.
        </p>
        <a-form>
          <a-form-item label="Cover Image:">
            <a-input type="file" @change="handleCoverImageFile" />
          </a-form-item>
          <a-form-item>
            <AppButton
              buttonText="Submit &amp; Save"
              @buttonAction="handleCoverUpload"
              :loading="formLoading"
            />
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
import PublishDesignerCard from './PublishDesignerCard'

export default {
  components: { PublishDesignerCard },
  props: {
    designer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup(props) {
    const store = useStore()
    const { formLoading } = useLoadingState()
    let avatar = ref()
    let coverImage = ref()

    const handleCoverUpload = () => {
      const payload = {
        designer_id: props.designer._id,
        photo: coverImage.value
      }
      store.dispatch('designer/UploadCoverAvatar', payload)
    }
    const handleAvatarUpload = () => {
      const payload = {
        designer_id: props.designer._id,
        photo: avatar.value
      }
      store.dispatch('designer/UploadProfileAvatar', payload)
    }
    const handleAvatarFile = (e) => {
      let formData = new FormData()
      formData.append('photo', e.target.files[0])
      avatar.value = formData
    }
    const handleCoverImageFile = (e) => {
      let formData = new FormData()
      formData.append('photo', e.target.files[0])
      coverImage.value = formData
    }

    return {
      handleAvatarUpload,
      handleCoverUpload,
      handleAvatarFile,
      handleCoverImageFile,
      formLoading
    }
  }
}
</script>

<style lang="scss" scoped></style>
